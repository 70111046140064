"use strict";
import { QueryClient } from "@tanstack/react-query";
import { injectedWithFallback } from "components/Web3Provider/injectedWithFallback";
import { WC_PARAMS, uniswapWalletConnect } from "components/Web3Provider/walletConnect";
import forkConfig from "forkConfig";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { abstractWalletConnector } from "@abstract-foundation/agw-react/connectors";
import { UniverseChainId, WEB_SUPPORTED_CHAIN_IDS } from "uniswap/src/types/chains";
import { createClient } from "viem";
import { createConfig, http } from "wagmi";
import { connect } from "wagmi/actions";
import { coinbaseWallet, injected, safe, walletConnect } from "wagmi/connectors";
export const wagmiConfig = createConfig({
  chains: [
    UNIVERSE_CHAIN_INFO[UniverseChainId.AbstractTestnet],
    ...WEB_SUPPORTED_CHAIN_IDS.map((chainId) => UNIVERSE_CHAIN_INFO[chainId])
  ],
  connectors: [
    injectedWithFallback(),
    walletConnect(WC_PARAMS),
    coinbaseWallet({
      appName: "Swap",
      // CB SDK doesn't pass the parent origin context to their passkey site
      // Flagged to CB team and can remove UNISWAP_WEB_URL once fixed
      appLogoUrl: ``,
      reloadOnDisconnect: false,
      enableMobileWalletLink: false
    }),
    ...forkConfig.uniSpecificFeaturesEnabled ? [uniswapWalletConnect()] : [],
    safe(),
    abstractWalletConnector()
  ],
  client({ chain }) {
    return createClient({
      chain,
      batch: { multicall: true },
      pollingInterval: 12e3,
      transport: http(chain.rpcUrls.appOnly.http[0])
    });
  }
});
export const queryClient = new QueryClient();
if (window.Cypress?.eagerlyConnect) {
  connect(wagmiConfig, { connector: injected() });
}
